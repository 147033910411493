import Navbar from '../components/navbar/navbar'
import Footer from '../components/footer/footer'
import BrainImage from '../assets/brain-dys.jpeg'
import BrainImage2 from '../assets/brain2.jpg'
import BrainImage3 from '../assets/brain3.jpg'
import BrainImage4 from '../assets/brain4.png'
import BrainImage5 from '../assets/brain5.png'
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextReader from '../components/text-reader/text-reader'

function HomePage() {
  return (
    <div className={'background'}>
    <div>
    <Navbar/>
     </div>
    <div style={{margin: '0px 20px', paddingTop: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} ><img src={BrainImage5} id='brain' alt='brain' width='80%' height='20%' /></div>
    <TextReader />  
    <Footer/>
    </div>
    );
}

export default HomePage;
