import Navbar from '../components/navbar/navbar'
import Footer from '../components/footer/footer'
import '../App.css'
import Button from 'react-bootstrap/Button';


// Init SpeechSynth API
const synth = window.speechSynthesis;
let isSpeaking = false

var isFirefox = typeof InstallTrigger !== 'undefined';

// Chrome 1+
var isChrome = !!window.chrome && !!window.chrome.webstore;

// Init voices array
let voices = [];

const getVoices = () => {
  voices = synth.getVoices();

  // Loop through voices and create an option for each one
  voices.forEach(voice => {
    // Create option element
    const option = document.createElement('option');
    // Fill option with voice and language
    option.textContent = voice.name + '(' + voice.lang + ')';

    // Set needed option attributes
    option.setAttribute('data-lang', voice.lang);
    option.setAttribute('data-name', voice.name);

  });
};

//Fix for duplication, run code depending on the browser
if (isFirefox) {
    getVoices();
}
if (isChrome) {
    if (synth.onvoiceschanged !== undefined) {
        synth.onvoiceschanged = getVoices;
    }
}


const cancelReading = () => {
  const textInput = document.getElementById('tts')


    if(isSpeaking){
        isSpeaking = false;
        synth.cancel();
        return;
    }
}

// Speak
const readText = () => {

    const textInput = document.getElementById('tts')


    if(isSpeaking){
        isSpeaking = false;
        synth.cancel();
        return;
    }

    voices = synth.getVoices();


  // Check if speaking
  if (synth.speaking) {
    console.error('Already speaking...');
    return;
  }
  if (textInput && textInput.value !== '') {
 
    // Get speak text
    const speakText = new SpeechSynthesisUtterance(textInput.value);

    // Speak end
    speakText.onend = e => {
      isSpeaking = false;
      console.log('Done speaking...');
    };

    // Speak error
    speakText.onerror = e => {
      console.error('Something went wrong');
    };

    // Set pitch and rate
    speakText.rate = 0.85;
    speakText.pitch = 1;
    speakText.voice = voices[0];         
    synth.speak(speakText);
    isSpeaking = true;
  }
};


function TextToSpeech() {


    return (
    <div className={'background'}>
                <div>
                <Navbar/>
                </div>
                <div className={'component-body'}>
                    <div style={{width: '100%', maxWidth: '100%', height: '85vh', marginTop: '30px'}}> 
                            <h2>Text Reader</h2>  <br/><br/>
                            <textarea id="tts" name="tts-area" cols="120"></textarea>
                            <div style={{paddingTop: '30px'}}>    
                            <Button variant="warning" onClick={readText} style={{marginRight: '20px'}}>Read the Text</Button>
                            <Button variant="warning" onClick={cancelReading} >Cancel reading</Button>
                             </div>
                            

                    </div>
                </div>
                <Footer/>
    </div>
    );
}

export default TextToSpeech;
