import Navbar from '../components/navbar/navbar'
import Footer from '../components/footer/footer'
function Hemingway() {
  return (
    <div className={'background'}>
                <div>
                <Navbar/>
                </div>
                <div className={'component-body'}>
                    <div style={{width: '100%', maxWidth: '100%', height: '100%'}}> 
                        <iframe src="https://hemingwayapp.com/" title="Hemingway Editor" width="85%" height='650px' padding='50px'> Hemingway Editor</iframe>  
                    </div>
                </div>
                <Footer/>
    </div>
    );
}

export default Hemingway;
