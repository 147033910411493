import { Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import TTSImage from '../../assets/tts-image.png'
import FunnelImage from '../../assets/funnel.png'
import EditImage from '../../assets/edit.png'

function TextReader() {

  return (
    <div className={'background'}>
                <div className={'component-body'}>
                <Row style={{paddingBottom: '150px'}}>
                  <Col>
                    <h1 style={{overflow: 'hidden'}}>Text Reader</h1>
                    <p style={{paddingTop: '50px', paddingLeft: '20px', paddingBottom: '30px'}}> Text Reader has benefits for everyone and it gives access to a content to a greater population, such as those with literacy difficulties, learning disabilities, reduced vision and those learning a language. It also opens doors to anyone else looking for easier ways to access digital content.<br/> 
                    </p>                    
                    <Button variant="warning" href='/tts' >Check out Text Reader</Button>{' '}
                  </Col>
                  <Col>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}><img src={TTSImage} width={'80%'}/></div>
                  </Col>
                </Row>
                <Row style={{paddingBottom: '150px'}}>
                  <Col>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}><img style={{display: 'flex', justifyContent: 'center', alignItems:'center'}} src={FunnelImage} width={'30%'}/></div>
                  </Col>
                  <Col>
                  <h1 style={{overflow: 'hidden'}}>Summarize</h1>
                    <p style={{paddingTop: '50px', paddingRight: '20px', paddingBottom: '30px'}}> Summarization is the technique for generating a concise and precise summary of voluminous texts while focusing on the sections that convey useful information, and without losing the overall meaning. This feature could prove to be highly valuable and essential tool to the Dyslexic community (average non-dyslexic person aswell). Summarize is AI powered tool which aims to reduce long paragraphs to 20% of their length. <br/> 
                    </p>                    
                    <Button variant="warning" href='/summarize' >Check out Summarize</Button>{' '}
                  </Col>
                </Row>
                <Row style={{paddingBottom: '150px'}}>
                  <Col>
                    <h1 style={{overflow: 'hidden'}}>Hemingway Editor</h1>
                    <p style={{paddingTop: '50px', paddingLeft: '20px', paddingBottom: '30px'}}> Hemingway Editor is a tool which could be very useful for dyslexic and non-dyslexic users. Ultimately, this tool improves your writing by making it bold and clear. <br/> 
                    </p>                    
                    <Button variant="warning" href='/hemingway' >Check out Hemingway</Button>{' '}
                  </Col>
                  <Col>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}><img src={EditImage} width={'30%'}/></div>
                  </Col>
                </Row>
                </div>
    </div>
    );
}

export default TextReader;
