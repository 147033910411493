import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/home-page';
import Hemingway from './pages/hemingway';
import Summarize from './pages/summarize'
import TextToSpeech from './pages/text-to-speech'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={< HomePage />} />
        <Route path="/123" element={< HomePage />} />
        <Route path="/hemingway" element={< Hemingway />} />
        <Route path="/summarize" element={< Summarize />} />
        <Route path="/tts" element={< TextToSpeech />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
