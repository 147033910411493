import Navbar from '../components/navbar/navbar'
import Footer from '../components/footer/footer'
import axios from 'axios'
import { Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react';

function Summarize() {

    let resp = ''

    const [addApiKey, setAddApiKey] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [text, setText] = useState('');
    const [requestResponse, setRequestResponse] = useState('');

    const handleApiChange = (event) => {   
     setApiKey(event.target.value)
    }

    const handleTextChange = (event) => {   
      setText(event.target.value)
     }

    const toggleField = () => {
      setAddApiKey(!addApiKey);
    }

    const addAPIKey = () => {
      setAddApiKey(false);
    }


    const summarize = () => {

      setRequestResponse('Please wait while the response is fetched!');
      const options = {
        method: 'POST',
        url: 'https://gpt-summarization.p.rapidapi.com/summarize',
        headers: {
          'content-type': 'application/json',
          'X-RapidAPI-Key': '', // add key here
          'X-RapidAPI-Host': 'gpt-summarization.p.rapidapi.com'
        },
        data: '{"text":"' + text+ '","num_sentences": 3}' 
        
    
      };
      
      axios.request(options).then(function (response) {
        console.log(response.data.summary);
        setRequestResponse(response.data.summary);
      }).catch(function (error) {
        apiKey || alert('API-Key Not Found: Visit rapidapi.com and get ')
        console.error(error);
        setRequestResponse('Error: ', error);
      });
    }

  return (
    <div className={'background'}>
                <div>
                <Navbar/>
                </div>
                <div className={'component-body'} style={{height: '90vh', marginTop: '50px'}}>
                <Button variant="secondary" onClick={summarize} width='50px'> Summarize </Button>{' '}
                <Button variant="secondary" onClick={toggleField} width='50px'> Add API Key </Button>{' '} 
              {addApiKey && (
                <div style={{margin: '20px'}}>
         <input type='text' width='300px' style={{margin: '5px', borderRadius: '5px'}} value={apiKey} onChange={handleApiChange} id='apiInputField' />
                <Button variant="secondary" style={{margin: '5px', position: 'relative', top: '-4px'}} onClick={addAPIKey}>Save key</Button>{' '}
                <div>  <small>(visit rapidapi.com and sing up for API key)</small></div>
                </div>
                
              )}
                <div style={{marginTop: '20px'}}>
                <Row>
                  <Col xs={12} sm>
                      <h2>Insert text here</h2>
                      <textarea id="tts" style={{maxHeight: '60vh', marginTop: '20px'}} name="tts-area" cols="50" value={text} onChange={handleTextChange}></textarea>
                  </Col>
                  <Col xs={12} sm>
                    <h2>Summarized Response</h2>
                      <textarea id="tts" name="tts-area" cols="50" style={{maxHeight: '60vh', marginTop: '20px'}} value={requestResponse}></textarea>
                    </Col>
                  </Row>
                </div>
                </div>
                <Footer/>
    </div>
    );
}

export default Summarize;
