import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './footer.css';

function Footer() {
  return (
    <div className={'footer'}>
     <Container>
        <Row className={'footer-row-elements'}>
            <Col className={'footer-row-elements'}><div>
                <h3>Mission Statement</h3> <br/>
                <p>
                   Dyslexi Help is a collection of tools which aim to improve the daily operations and the digital experience of the people who are diagnosed with Dyslexia. <br/><br/> Ultimately it is free to use and non profitable. The code can be found on github (insert link here) & it is open to improvement and community feedback. 
                </p>
            </div> </Col>
            <Col className={'footer-row-elements'}>
            <div>
            <h3> Contact Us</h3> <br/>
                 <ul>
                <li>Twitter: @dyslexiHelp</li>
                <li>Facebook: Dyslexi Help</li>
                <li>Instragram: DyslexiHelp</li>
                <li>Youtube: DyslexiHelp</li>
                <li>Linkedin: Dyslexi Help</li>
                <li>email: contact@dyslexi.help</li>
            </ul>
            </div>
             </Col>
        </Row>
        <Row className={'footer-text'}>
            <hr/>
            <p className={'footer-copyrights'}>All rights are reserved. The content of this website cannot be reproduced without approval.</p>
        </Row>
        <Row>
            <p className={'footer-copyrights'}>Stefan Apostolovski - 165038 @ FCSE, Skopje 2022</p>
        </Row>
     </Container>
    </div>
  );
}

export default Footer;